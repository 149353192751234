import React, { useContext, useEffect, useRef } from "react";
import { Mui, MuiLab, PageHeadingContext } from "@osu/react-ui";
import useStyles from "../../styles";
import { ACTION_STATUS, OSU_EMAIL_EXTENSIONS } from "../../../util/constants";

const buildRequestDetails = (requestBody, classes) => {

    let requestTypeHasSubtype = requestBody?.subtype ? true : false;
    let arrayOfCommentLines = requestBody?.details ? requestBody.details.replace(/\n\n/g, "\n \n").split("\n") : [];
    let detailsWithLineBreaks = arrayOfCommentLines.map((comment, index) => {
        if(comment === " ") {
            return <Mui.Box  key={index + "deets-with-line"} style={{ marginTop: "0.9rem" }}></Mui.Box>
        } 
        return <Mui.Typography key={index + "deets-with-line"} className={classes.smallText} >{comment}</Mui.Typography>
    });
    return (
        <div>
            <Mui.Box style={{ marginTop: "1rem" }}>
                <Mui.Typography  variant={"string"} className={classes.bold} >{requestBody.type}:</Mui.Typography>
                {requestTypeHasSubtype && <Mui.Typography variant={"string"} > {requestBody.subtype}</Mui.Typography>}
            </Mui.Box>
            {requestBody?.fileName &&
                <Mui.Box marginBottom="0.5rem">
                    <Mui.Typography variant={"string"}><b>Attachment:</b> {requestBody.fileName}</Mui.Typography>
                </Mui.Box>
            }
            <Mui.Box>
                {detailsWithLineBreaks}
            </Mui.Box>
        </div>
    )
}

const buildContactInformationDetails = (contactBody) => {
    return (
        <div>
            <Mui.Box style={{ marginTop: "1rem" }}>
            <Mui.Typography variant={"body2"} style={{ "overflow-wrap": "break-word" }}>{contactBody.firstName} {contactBody.lastName} </Mui.Typography>
            </Mui.Box>
            <Mui.Box style={{ marginBottom: "1rem" }}>
                <Mui.Typography variant={"body2"} style={{ "overflow-wrap": "break-word" }}>{contactBody.email}</Mui.Typography>
            </Mui.Box>
        </div>
    )
}


const Alert = ({ email = "", firstName, service, severity}) => {
    const error = severity === ACTION_STATUS.ERROR
    const alertRef = useRef()
    let content = ""
    if(severity === ACTION_STATUS.SUCCESS) {
        const emailTokens = email.split("@");
        const isOsuEmail = OSU_EMAIL_EXTENSIONS.includes(emailTokens?.[1]);
        if(service === "OTDIServiceNow" && !isOsuEmail) {
            content = `Thank you, ${firstName}. Your request has been submitted to the IT Service Desk and will be reviewed as quickly as possible. ` +
                `If you have additional information to provide or if you need urgent assistance, please contact the IT Service Desk by phone at 614-688-4357.`;
        } else {
            content = `Thank you, ${firstName}. Your request is being processed and you will receive an email confirmation shortly.`;
        }
    } else if (error) {
        content = (
            <span>
                Please try to submit your request again. If you continue to experience problems contact us at <Mui.Link href={`mailto: OTDI-BuckeyeAssistHelp@osu.edu`}>OTDI-BuckeyeAssistHelp@osu.edu</Mui.Link>.
            </span>
        );
    }
    
    useEffect(() => {
        if(alertRef?.current && content) {
            alertRef.current.focus()
        }
    }, [content])

    if(content) {
        return (
            <Mui.Box
            style={{ marginBottom: "1rem" }}
                component={MuiLab.Alert}
                variant="outlined"
                severity={error ? "error" : "success"}
            >        
                <MuiLab.AlertTitle ref={alertRef} tabIndex="-1">{error ? "Something Went Wrong" : "Success!"}</MuiLab.AlertTitle>
                <Mui.Typography id={`success-content`} variant="subtitle1">
                    {content}
                </Mui.Typography>
            </Mui.Box>
        )
    }
    return null
}

export default function Review(props = {}) {
    const classes = useStyles({ elevation: props?.elevation });
    const { setPageTitle } = useContext(PageHeadingContext);

    let contactInformationState = props?.stepState?.CONTACT_INFORMATION;
    let requestState = props?.stepState?.REQUEST;

    let submitted = props.requestStatus === "success";
    let submissionFailed = props.requestStatus === "error";
    let contactBody = {
        firstName: contactInformationState?.values?.firstName,
        lastName: contactInformationState?.values?.lastName,
        email: contactInformationState?.values?.email
    };
    let contactDetails = buildContactInformationDetails(contactBody);
    let requestBody = {
        type: requestState?.values?.topic,
        subtype: requestState?.values?.subtopic,
        details: requestState?.values?.message,
        fileName: requestState?.values?.file?.name
    };
    let requestDetails = buildRequestDetails(requestBody, classes);

    const requestHeaderText = "Request";
    const confirmationText = "Please confirm the following request details:";

    const ContactInformationText = "Contact Information";

    useEffect(() => {
        if(props.requestStatus === ACTION_STATUS.ERROR) {
            setPageTitle(`Error Submitting Request`)
        } else if(props.requestStatus === ACTION_STATUS.SUCCESS) {
            setPageTitle(`Successfully Submitted Request`)
        } else if(props.requestStatus === ACTION_STATUS.LOADING) {
            setPageTitle(`Submitting Request`)
        }
    }, [props.requestStatus, setPageTitle])


    return (
        <div>
            {(submitted || submissionFailed) && <Alert firstName={contactBody.firstName} email={contactBody.email} service={requestState?.values?.service} severity={submissionFailed ? ACTION_STATUS.ERROR : ACTION_STATUS.SUCCESS} />}
            {(!submitted && !submissionFailed) &&
                <Mui.Box style={{ marginBottom: "1rem" }} display="flex">
                    <Mui.Typography component="h3" variant="h5">
                        {confirmationText}
                    </Mui.Typography>
                </Mui.Box>
            }
            <Mui.Container style={{ border: "1px solid lightgray", borderRadius: "8px" }} variant="outlined" >
                <Mui.Box style={{ marginTop: "1rem" }} display="flex">
                    <Mui.Typography component="h3" variant="h5" className={"bold"}>
                        {requestHeaderText}
                    </Mui.Typography>
                </Mui.Box>
                {requestDetails}

                <Mui.Box style={{ marginTop: "1rem" }} display="flex">
                    <Mui.Typography component="h3" variant="h5" className={"bold"}>
                        {ContactInformationText}
                    </Mui.Typography>
                </Mui.Box>
                {contactDetails}

            </Mui.Container>
            <Mui.Typography className="margin-top-1">
                <Mui.Typography component="span" className={classes.bold}>Please note: </Mui.Typography>
                {" "}Submitting multiple help requests and e-mails for the same reason may delay our response time.
            </Mui.Typography>
        </div>



    );
}